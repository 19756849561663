@include media-query($one-col-res) {
	ul.right-sidebar {
		margin-left: 1%;
	}
}
@include media-query($one-col-res) {
	body {
		background-image: url('../img/kidsil_bg_mobile.jpg');
	}
	ul.post-list {
		flex-basis: 100%;
	}
	ul.right-sidebar {
		display: none;
	}
}