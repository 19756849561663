#page-not-found {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    clear: both;
    margin: 20px auto 20px auto;
    padding: 0 20px 20px 20px;
    position: relative;
    h1.title-404 {
        color: #993333;
        font-size: 2.4em;
        font-weight: 700;
        margin-bottom: .5em;
        margin-top: 1.5em;
    }
    h6 {
        font-size: 1em;
    }
}