.hint,[data-hint] {
	position: relative;
	display: inline-block;
	border-bottom: 2px dotted #aaa;
	cursor: pointer;
	&:before, &:after {
		position: absolute;
		@include prefix(transform, translate3d(0,0,0), webkit moz);
		@include prefix(transition, .3s ease, webkit moz);
		@include prefix(transition-delay, 0, webkit moz);
		visibility: hidden;
		opacity: 0;
		z-index: 1000000;
		pointer-events: none;
	}
	&:hover:before, &:hover:after, &:focus:before, &:focus:after {
		visibility: visible;
		opacity: 1;
	}
	&:hover:before, &:hover:after {
		@include prefix(transition-delay, 100ms, webkit moz);
	}
	&:before {
		content: '';
		position: absolute;
		background: transparent;
		border: 6px solid transparent;
		z-index: 1000001;
	}
	&:after {
		box-shadow: 4px 4px 8px rgba(0,0,0,0.3);
		content: attr(data-hint);
		background: #555;
		color: #fff;
		padding: 8px 10px;
		font-size: 0.75em;
		line-height: 12px;
		text-shadow: 0 -1px 0 #000;
		white-space: nowrap;
	}
}

[data-hint='']:before,[data-hint='']:after {
	display: none !important;
}
.hint--top {
	&:before {
		border-top-color: #383838;
		margin-bottom: -12px;
	}
	&:after {
		margin-left: -18px;
	}
	&:before, &:after {
		bottom: 100%;
		left: 50%;
	}
	&:hover:before, &:hover:after, &:focus:before, &:focus:after {
		@include prefix(transform, translateY(-8px), webkit moz);
	}
}