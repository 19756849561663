//Post views within Home page / Category page
img {
    border: 0;
    display: block;
    height: auto;
    margin: 5px auto;
    max-width: 100%;
    vertical-align: middle;
    &.thumb {
        display: inline-block;
    }
    &.alignleft {
        margin-left: 0;
    }
}
.post-content {
	ul {
		li {
			margin-left: 8px;
		}
	}
    figcaption {
        font-style: italic;
        font-size: 0.75em;
    }
}

//Post page itself
.single-col {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    clear: both;
    line-height: 28px;
    margin: 20px auto 20px auto;
    padding: 0 20px 20px 20px;
    position: relative;   
}
article.post {
    font-size: 1.35em;
    #breadcrumbs li {
        font-size: 12px;
    }
    h1.post-title {
        font-size: 2.4em;
        text-align: center;
        margin-top: 0.7em;
        margin-bottom: 0.3em;
        line-height: 1em;
    }
    h3 {
        font-size: 1.5em;
    }
    h4 {
        font-size: 1.2em;
        line-height: 0.5em
    }
    .post-meta {
        color: #9f9f9f;
        font-size: 12px;
        text-align: center;
        line-height: 14px;
    }
    .navigation {
        color: #111111;
        display: block;
        font-size: 13px;
        height: 28px;
        line-height: 28px;
        margin: 20px 0;
        padding: 0 5px;
        a {
            color: #aaaaaa;
            padding: 4px 10px;
        }
        .previous {
            float:left;
        }
        .next {
            float: right;
        }
    }
    .youtube-clip {
        position: relative;
        padding-bottom: 56.25%; // 16:9
        padding-top: 25px;
        height: 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .post-tags {
        margin-top: 20px;
    }
    .post-tags, .post-category {
        font-size: 0.7em;
        font-weight: 700;
        span {
            font-weight: 400;
        }
    }
    #disqus_thread {
        margin-top: 15px;
    }
    table {
        margin: 20px auto 20px auto;
        border-collapse: collapse;
        text-align: left;
        tr {
            th {
                font-weight: bold;
                cursor: pointer;
            }
            td, th {
                border: 1px solid #ddd;
                padding: 8px;
            }
            td {
                font-size: 16px;
            }
        }
        tr:nth-child(even) {
            background-color: #f0f0f0;
        }
    }
}

article.post table.workout tr {
    th {
        font-size: 16px;
    }
    td {
        font-size: 15px;
    }
}