@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }
  #{$property}: $value;
}
html {
    position: relative;
}
body {
    background-image: url('../img/kidsil_bg.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    color: #555555;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
}
#bg-layout {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.3;
    background-color: #fff;

}
.announcement-bar {
    display: flex;
    position: relative;
    background-color: rgba(7, 104, 159, 0.9);
    color: #fff;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    @media (min-width: 768px) {
        font-size: 16px;
        padding: 8px 0;
    }
    
    a {
        color: #fff;
        font-weight: bold;
        &:hover, &:active {
            color: #fff;
            text-decoration: none;
        }
    }
    
    .close-button {
        color: #fff;
        cursor: pointer;
        padding: 2px;
        @media (min-width: 768px) {
            &:hover, &:active {
                color: #fff;
            }
        }
    }
}
a {
    text-decoration: none;
    color: #0066cc;
    &:hover, &:active, &:focus {
        color: #444;
    }
}
p, hr, dl, pre, form, address, blockquote {
    margin: 1.56em 0;
}
p {
    word-wrap: break-word;
}
ul {
    list-style-type: disc;
    padding-left: 20px;
}
.container {
    margin: 0 auto;
    max-width: 1920px;
    padding: 0px 25px;
    .body-page & {
        max-width: 1280px;
    }
}
.site-name {
    display: block;
    font-size: 2.05em;
    line-height: 1em;
    padding-top: 20px;
    a {
        color: #66b3ff;
        font-weight: 700;
        text-decoration: none;
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
    }
}
.site-description {
    display: block;
    font-size: 1.2em;
    font-weight: normal;
    margin: 10px 0;
    line-height: 28px;
}
#menu-categories {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #d9d9d9;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#d9d9d9));
    @include prefix(background-image, linear-gradient(top,#fff,#d9d9d9), webkit moz ms o);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#fff,endColorstr=#d9d9d9);
    clear: both;
    &:after {
        clear: both;
        content: '.';
        display: block;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
    li {
        border: 0;
        display: block;
        float: left;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 5;
        a {
            border-left: 1px solid #d9d9d9;
            color: #333;
            cursor: pointer;
            display: block;
            font-size: 14px;
            font-weight: 700;
            height: 45px;
            line-height: 45px;
            margin: 0;
            padding: 0 0.9em;
            position: relative;
            text-decoration: none;
            text-shadow: 0 -1px 0 #fff;
            white-space: nowrap;
            &:hover {
                background-color: #bfbfbf;
                background-image: -webkit-gradient(linear,left top,left bottom,from(#e6e6e6),to(#bfbfbf));
                @include prefix(background-image, linear-gradient(top,#e6e6e6,#bfbfbf), webkit moz ms o);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#e6e6e6,endColorstr=#bfbfbf);
            }
        }
        &.current-menu-item a {
            background-color: #343434;
        }
        &.menu-item-right {
            float: right;
        }
    }
}
.clearfix {
    clear: both;
}

.wrapper .home {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.post-list {
    flex-basis: 66%;
    padding-right: 15px;
    & > li {
        background-color: #EFEFEF;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 20px;
        display: block;
        h2 {
            font-size: 2.34em;
            margin-top: 0;
            margin-bottom: 17px;
            line-height: 1em;
        }
        .post-meta {
            color: #9f9f9f;
            font-size: 0.85em;
        }
        .post-tags {
            margin-top: 20px;
        }
        .post-tags, .post-category {
            font-size: 0.8em;
            font-weight: 700;
            span {
                font-weight: 400;
            }
        }
    }
}
.right-sidebar {
    flex-basis: 33%;
    margin-left: 15px;
    & > li {
        background-color: #f9f9f9;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        margin: 0 0 20px;
        padding: 20px;
        display: block;
        h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 23px;
            margin: 0;
            padding: 0 0 20px 0;
        }
    }
}
.post-list, .right-sidebar {
    margin-top: 20px;
    list-style-type: none;
    padding: 0;
}

#breadcrumbs {
    list-style-type: none;
    padding-left: 0;
    li {
        display: inline-block;
        font-size: 14px;
    }
}


//Footer
footer {
    h4.footer-heading {
        float: left;
        margin-top: 3px;
        color: #cfcfcf;
        a {
            text-shadow: 0px 0px 10px rgba(225, 225, 225, 1);
        }
    }
    .social-media-list {
        list-style: none;
        float: right;
        a {
            background-image: url('../img/social-icons.png');
            background-position: -1px -1px;
            background-repeat: no-repeat;
            display: inline-block;
            width: 24px;
            height: 24px;
            &.github-icon {
                background-position: -27px -1px;
            }
            &.twitter-icon {
                background-position: -53px -1px;
            }
            &.google-icon {
                background-position: -1px -27px;
            }
            &.linkedin-icon {
                background-position: -27px -27px;
            }
        }
    }
}
