.category-body, .tag-body {
	line-height: 21px;
	.left-col {
		display: inline-block;
		width: 66%;
		#breadcrumbs {
			margin-top: 28px;
			margin-bottom: 0;
		}
		.post-list {
			width: 100%;
			margin-top: 10px;
			& > li {
				padding-left: 0;
				background-color: transparent;
				margin-bottom: 10px;
				.read-more {
					margin-top: 10px;
				}
				.post-tags {
					margin-top: 10px;
				}
			}
		}
	}
	.right-sidebar {
		margin-left: 14px;
	}
}